import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const StyledH2 = styled.h2`
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  color: #402b2b;
  @media (max-width: 920px){
    font-size: 100%;
  }
`;

const StyledH4 = styled.h4`
  font-family: Helvetica;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
  color: #0a2338;
  @media (max-width: 920px){
    font-size: 100%;
  }
`;

const StyledH5 = styled.h5`
  font-weight: 400;
  font-family: "Nunito Sans";
  color: #4B5D68;
  @media (max-width: 920px){
    font-size: 80%;
  }
`;

const StyledH6 = styled.h6`
  font-weight: 700;
  font-family: "Nunito Sans";
  color: #0A2338;
  @media (max-width: 920px){
    font-size: 70%;
  }
`;

const StyledTd = styled.td`
  border: 1px solid #ddd;
  padding: 1% 3%;
`;

const StyledTh = styled.th`
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f1efe2;
  color: black;
  border: 1px solid #ddd;
  padding: 1% 3%;
`;
const StyledTr = styled.tr`
  background-color: white;
`;
const MembershipTable = () => {
  return (
    <table className="table" id="membership">
      <StyledTr>
        <StyledTh className="align-middle">
          <StyledH4 style={{ fontFamily: "helvetica", fontWeight: "bold", color: "black"}}> Membership Perks </StyledH4>
          <StyledH6 style={{lineHeight: "120%", fontFamily: "nunito", color: "black", fontWeight: "700"}}>Available to all genders from any university</StyledH6>
        </StyledTh>
        <StyledTh style={{ padding: "2%" }}>
          <StyledH6
            style={{
              textAlign: "center",
              lineHeight: "120%",
              fontFamily: "Rubik",
              color: "#E57154",
              fontWeight: "700",
            }}
          >
            GENERAL MEMBER
          </StyledH6>
          <StyledH2 style={{ textAlign: "center" }}>Free</StyledH2>
        </StyledTh>
        <StyledTh style={{ padding: "2%" }}>
          <StyledH6
            style={{
              textAlign: "center",
              lineHeight: "120%",
              fontFamily: "Rubik",
              color: "#E57154",
              fontWeight: "700",
            }}
          >
            WITS VIP MEMBER
          </StyledH6>
          <StyledH2 style={{ textAlign: "center" }}>$15</StyledH2>
        </StyledTh>
        <StyledTh style={{ padding: "2%" }}>
          <StyledH6
            style={{
              textAlign: "center",
              lineHeight: "120%",
              fontFamily: "Rubik",
              color: "#E57154",
              fontWeight: "700",
            }}
          >
            MUST BE FEMALE/NON-BINARY WESTERN STUDENT
          </StyledH6>
        </StyledTh>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Workshops and Educational Events
          </StyledH5>
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>Get added to our mailing list</StyledH5>
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Access to Community Slack channel
          </StyledH5>
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Participate in Technical Slack Challenges
          </StyledH5>
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}> 
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Recruiting Series
          </StyledH5>
        </StyledTd>   
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Panels & Discussions
          </StyledH5>
        </StyledTd>       
        <StyledTd style={{textAlign: "center"}}> 
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>      
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Access to private communities on Slack
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>       
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Resume Editing and Review
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Technical Mock Interviews
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Database of opportunities and internships
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Bi-weekly newsletters with exclusive opportunities and internships
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Discount code for merch* (only until Oct 1)
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Exclusive raffles to win prizes
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd> </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Resume database for recruiters
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px"}} />
        </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Exclusive access to our Ada Mentorship Program
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Exclusive access to Big CSters Mentorship Program
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px" }} />
        </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd>
          <StyledH5 style={{ color: "black", marginTop: "1%" }}>
            Coffee Chat with Alumni/Professionals
          </StyledH5>
        </StyledTd>
        <StyledTd> </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px"}} />
        </StyledTd>
        <StyledTd style={{textAlign: "center"}}>
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "#E68976", fontSize: "20px"}} />
        </StyledTd>
      </StyledTr>
    </table>
  );
};
export default MembershipTable;
