import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MembershipTable from "./table/table";
import Modal from "./modal/Modal";
import JoinSlack from "../common/components/slack/Slack";


// images
import membershippic from "../images/membershippic.png";
import membershipwave from "../images/membership-wave.svg";


const StyledButton = styled.button`
  background: #E68976;
  color: white;
  font-size: 18px;
  padding:  20px 40px;
  border-radius: 50px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  &:hover {
    background-color: #e69f91;
    border-radius: ""};
  }
`;

const StyledH1 = styled.h1`
  color: #0a2338;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  line-height: 110%;
  font-size: 3.5rem;
`;
const StyledH4 = styled.h4`
  font-family: Helvetica;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
  color: #0a2338;
`;

const Membership = () => {
  const [ show, setShow ] = useState(false);

  useEffect(() => {
    setShow(false)
  }, []);

  return (
    <div style={{ height: "100%", display: "block", margin: "0"}}>
      <section style={{ backgroundColor: "#E68976", paddingTop: "10%" }}>
        <div className="container">
          <div className="row reverse-col">
            <div className="col-lg-6 m-auto">
              <StyledH1>
                Break into tech <br />
                with WITS!
              </StyledH1>
              <br />
              <br />
              <StyledH4 style={{ fontFamily: "nunito", color: "#0A2338" }}>
                Ready to take a stand and join the tech community? Become a
                member of the WITS family and receive access to a wonderful
                network of changemakers and resources to get you involved in
                tech!
              </StyledH4>
            </div>
            <div className="col-lg-6">
              <img src={membershippic} alt="main pic" width="90%" />
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </section>
      <img
        src={membershipwave}
        className="w-100 mb-5"
        alt="main pic"
        style={{ marginTop: "-5px" }}
      />
      <div className="container" style={{overflow: "hidden"}}> <MembershipTable /> </div>
      <JoinSlack />
      <section style={{padding: "5% 0", marginTop: "1%" }}>
        <div className="container">
          <h2 style={{ textAlign: "center" }}>Ready to join a community of boss women+?</h2>
          <h5 style={{textAlign: 'center', fontWeight: 'bold', color: '#402B2B'}}>Memberships open now! </h5>
          <br />
          <div className="row justify-content-center">
            <StyledButton onClick={() => setShow(true)} >Let's do it </StyledButton>
          </div>
        </div>
        <Modal onClose={() => setShow(false)} show={show} />
      </section>
    </div>
  );
};
export default Membership;
